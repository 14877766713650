.list-unstyled {
  @include list-unstyled;
}

.mt-30 {
  margin-top: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}
.mt-15 {
  margin-top: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.spinner-border {
  border-style: dotted !important;
  color: #252527  !important;
}
